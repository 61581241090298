<ng-container *ngIf="!passwordChanged; else passwordChangedBlock">
  <div class="icon-box info"><i class="icon icon-key-01"></i></div>
  <h1>Nouveau mot de passe</h1>
  <div class="subtitle">Choisissez un nouveau mot de passe unique et gardez le précieusement.</div>

  <form (ngSubmit)="onSubmit()"
        [formGroup]="changePasswordFormGroup">
    <div class="form-group">
      <ed-textfield id="email"
                    [type]="'password'"
                    [formControlName]="'password'"></ed-textfield>
    </div>

    <div class="button-group">
      <ed-button [type]="ButtonTypes.Submit"
                 [disabled]="changePasswordFormGroup.invalid">Réinitialiser mon mot de passe</ed-button>
    </div>
  </form>

  <div class="back">
    <a routerLink="/"><i class="icon icon-arrow-left"></i>Se connecter</a>
  </div>
</ng-container>

<ng-template #passwordChangedBlock>
  <div class="icon-box valid"><i class="icon icon-check-circle"></i></div>
  <h1>Mot de passe enregistré</h1>
  <div class="subtitle">Votre nouveau mot de passe a été enregistré.<br>Vous pouvez dès à présent vous connecter.</div>
  <ed-button class="full-width" routerLink="/">Se connecter</ed-button>
</ng-template>
