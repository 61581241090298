import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';
import { Subscription } from 'rxjs';
import { parseJwt } from '../../shared/all.helpers';
import { markAllChildrenAsDirty, spreadErrorField } from '../../shared/form.helpers';
import { hasError } from '../../shared/property.helpers';
import { emailValidator } from '../../shared/validators/email.validator';
import { passwordValidator } from '../../shared/validators/password.validator';
import { phoneValidator } from '../../shared/validators/phone.validator';
import { PublicService } from '../public.service';

@Component({
  selector: 'cil-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  public ButtonTypes = ButtonTypes;
  public passwordType = 'password';

  public step = 0;
  public passwordFilled = false;

  public signupFormGroup: FormGroup = this.formBuilder.group({
    personalInfo: this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobilePhone: ['', [Validators.required, phoneValidator()]],
    }),
    securityInfo: this.formBuilder.group({
      email: ['', [emailValidator()]],
      password: ['', [passwordValidator()]],
      cgu: [false, Validators.requiredTrue],
      rgpd: [false, Validators.requiredTrue],
    }),
  });

  private _subscriptions: Subscription[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly formBuilder: FormBuilder,
    private readonly publicService: PublicService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams['token'];

    const validTokenSub = this.publicService.isInviteTokenValid(token).subscribe((valid: boolean) => {
      if (!valid) {
        this.alertService.warning(
          'Utilisateur existant',
          'Le compte a déjà été créé. Vous pouvez vous connecter avec vos identifiants.',
        );
        this.router.navigateByUrl('/login');
      }
    });
    this._subscriptions.push(validTokenSub);

    const payload = parseJwt(token);

    const securityInfo = this.signupFormGroup.get('securityInfo');
    const personalInfo = this.signupFormGroup.get('personalInfo');

    securityInfo?.patchValue({
      email: payload.sub.toLowerCase(),
    });

    securityInfo?.get('email')?.disable();

    personalInfo?.patchValue({
      firstName: payload.firstName,
      lastName: payload.lastName,
      mobilePhone: payload.mobilePhone,
    });
  }

  public ngOnDestroy(): void {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }

  public onSubmit(): void {
    const token = this.activatedRoute.snapshot.queryParams['token'] || '';

    const sub = this.publicService.signup(this.signupFormGroup.value, token).subscribe({
      next: () => (this.passwordFilled = true),
      error: (err) => {
        this.alertService.error('Inscription', err.error.content?.reason || 'Le jeton est invalide.');

        spreadErrorField(err, this.signupFormGroup);
      },
    });
    this._subscriptions.push(sub);
  }

  public hasError(formGroupName: string, formControlName: string): boolean {
    return hasError(this.signupFormGroup, formGroupName, formControlName);
  }

  public nextStep(): void {
    if (!this.signupFormGroup.get('personalInfo')?.valid) {
      markAllChildrenAsDirty(this.signupFormGroup.get('personalInfo') as FormGroup);

      return;
    }

    this.step = 1;
  }

  public previousStep(): void {
    const securityInfo = this.signupFormGroup.get('securityInfo');
    securityInfo?.patchValue({
      cgu: false,
      rgpd: false,
    });

    this.step = 0;
  }

  public toggleVisibility(): void {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  public getVisibilityToggleButtonIcon(): string {
    let icon = 'icon icon-eye';

    if (this.passwordType === 'text') {
      icon += '-off';
    }

    return icon;
  }

  public getDownloadPdfCgu(): string {
    return `/assets/CGU_PHILOGI_30072024.pdf`;
  }
}
