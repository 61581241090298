import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class PublicService {
  private _baseUrl = `${environment.api.baseUrl}`;

  constructor(private readonly http: HttpClient) {}

  /**
   * Always return true without any error to not leak email existence
   */
  public forgotPassword(email: string): boolean {
    const url = `${this._baseUrl}/forgot-password`;

    this.http.post<boolean>(url, { email }).subscribe();

    return true;
  }

  public changePassword(token: string, password: string): Observable<boolean> {
    const url = `${this._baseUrl}/change-password`;

    return this.http.post<boolean>(url, { token, password });
  }

  public signup(user: any, token: string): Observable<boolean> {
    const url = `${this._baseUrl}/users/signup`;

    return this.http.post<boolean>(url, { ...user, token });
  }

  public isInviteTokenValid(token: string): Observable<boolean> {
    const url = `${this._baseUrl}/users/isInviteTokenValid?token=${token}`;

    return this.http.get<boolean>(url);
  }

  public consentSeenPage(token: string): Observable<boolean> {
    const url = `${this._baseUrl}/consent/seenPage`;

    return this.http.post<boolean>(url, { token });
  }

  public validateConsent(token: string): Observable<boolean> {
    const url = `${this._baseUrl}/consent/validate`;

    return this.http.post<boolean>(url, { token });
  }
}
