import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonTypes } from '@ed---interne/ng-uui-components';
import { emailValidator } from '../../shared/validators/email.validator';
import { PublicService } from '../public.service';

@Component({
  selector: 'cil-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public ButtonTypes = ButtonTypes;

  public forgotPasswordFormGroup: FormGroup = this.formBuilder.group({
    email: ['', [emailValidator()]],
  });

  public emailSentTo: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly publicService: PublicService,
  ) {}

  public onSubmit(): void {
    const email = (this.emailSentTo || this.forgotPasswordFormGroup.value.email || '').trim();

    this.publicService.forgotPassword(email);

    this.emailSentTo = email.toLowerCase();
  }
}
