import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'cil-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent {
  public pageTitle = '';

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
      )
      .subscribe((activatedRoute: ActivatedRoute) => {
        this.pageTitle = activatedRoute.snapshot.data['title'] || '';
      });
  }
}
