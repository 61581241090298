<div class="icon-box info"><i class="icon icon-log-in-04"></i></div>
<h1>Se connecter</h1>

<form (ngSubmit)="onSubmit()" [formGroup]="loginFormGroup">
  <div class="form-group">
    <ed-textfield id="email"
                  [type]="'email'"
                  [placeholder]="'Votre email'"
                  [displayError]="displayError"
                  [formControlName]="'email'"></ed-textfield>
  </div>
  <div class="form-group">
    <ed-textfield id="password"
                  [type]="'password'"
                  [placeholder]="'••••••••'"
                  [formControlName]="'password'"></ed-textfield>
  </div>
  <div *ngIf="loginFormGroup.get('email')?.hasError('email')" class="alert">
    Email invalide
  </div>

  <div class="button-group">
    <ed-button [type]="ButtonTypes.Submit"
               [loading]="loading"
               [disabled]="!loginFormGroup.valid">Se connecter</ed-button>
  </div>
</form>

<a routerLink="/forgot-password">Mot de passe oublié</a>
