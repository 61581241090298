import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

export const tokenGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);

  if (next.queryParams['token']) {
    return true;
  }

  return router.parseUrl('/login');
};
