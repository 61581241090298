import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';
import { PublicService } from '../public.service';

@Component({
  selector: 'cil-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  public ButtonTypes = ButtonTypes;

  public changePasswordFormGroup: FormGroup = this.formBuilder.group({
    password: [''],
  });

  public passwordChanged = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly publicService: PublicService,
  ) {}

  public onSubmit(): void {
    const password = this.changePasswordFormGroup.value.password || '';
    const token = this.activatedRoute.snapshot.queryParams['token'] || '';

    this.publicService.changePassword(token, password).subscribe({
      next: () => this.alertService.valid('Mot de passe', 'Changement réussi !'),
      error: () => this.alertService.error('Mot de passe', 'Erreur lors du changement de mot de passe'),
    });

    this.passwordChanged = true;
  }
}
