import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PresentationLayoutComponent } from './layouts/presentation-layout/presentation-layout.component';
import { LoginComponent } from './login/login.component';
import { PublicComponent } from './public.component';
import { SignupComponent } from './signup/signup.component';
import { tokenGuard } from './token.guard';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: PresentationLayoutComponent,
        children: [
          {
            path: '',
            component: LoginComponent,
          },
        ],
      },
      {
        path: 'forgot-password',
        component: PresentationLayoutComponent,
        children: [
          {
            path: '',
            component: ForgotPasswordComponent,
          },
        ],
      },
      {
        path: 'change-password',
        component: PresentationLayoutComponent,
        canActivate: [tokenGuard],
        children: [
          {
            path: '',
            component: ChangePasswordComponent,
          },
        ],
      },
      {
        path: 'signup',
        component: PresentationLayoutComponent,
        canActivate: [tokenGuard],
        children: [
          {
            path: '',
            component: SignupComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
